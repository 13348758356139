import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


import {
    getBookmark,
    getCreatorFollows,
    getLikes,
    getReleasePost,
    useScreenSize,
    useIsBack,
    restore_numbers_in_filename
} from '../../Utils';
import { RequestFanbusy } from '../../Services';
import { Loader, Post, NoContent, EndOfPage, BannerBecome } from '../../Components';
import { AppContainer, AsideContainer, AsidePeople, SuggestionContainer } from '../../Containers';
import { MdEditNote } from 'react-icons/md';
import throttle from 'lodash.throttle';


const HomeView = () => {
    const { t } = useTranslation();
    const users = useSelector(state => state.users);
    const [completedRequests, setCompletedRequests] = useState(0);
    const [listPostRelease, setListPostRelease] = useState([]);
    const [isPopoverVisible, setPopoverVisible] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const baseLink = 'https://dyu0tgtvswtj8.cloudfront.net/fanbusy_';
    const [state, setState] = useState({
        posts: [],
        stories: [],
        isLoading: true,
        isLoadingMorePost: false
    });

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 5,
        last_page: 0,
        current_page: 0,
    })
    const nextPage = pagination.current_page + 1;
    const [listBookmark, setListBookmark] = useState([]);
    const [listLikes, setListLikes] = useState([]);
    const screenSize = useScreenSize();
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium';

    const isBack = useIsBack();
    React.useEffect(() => {
        if (isBack) {
            window.scrollTo(0, 0);
        }
    }, [isBack]);

    const handleClosePopup = () => {
        if (isPopoverVisible) {
            setPopoverVisible(false)
        }
    }

    useEffect(() => {
        let follows = getCreatorFollows(users.access_token);
        follows.then((value) => {
            setState((prev) => ({ ...prev, follows: value }));
        });

        let bookmarks = getBookmark(users.access_token);
        bookmarks.then((value) => {
            setListBookmark(value);
        });

        let likes = getLikes(users.access_token, 'POST');
        likes.then((value) => {
            setListLikes(value);
        });

        let releasePost = getReleasePost(users.access_token);
        releasePost.then((value) => {
            setListPostRelease(value);
        });

        const getCreatorPost = async () => {
            try {
                let res = await RequestFanbusy(`posts/subscribe/creator`, 'GET', '', users.access_token);
                const pagination = res.response.paginage;
                if (res.status === 200) {
                    const modifiedData = res.response.data.map(post => {
                        return {
                            ...post,
                            illustrations: post.illustrations.map(illustration => {

                                if (illustration.link.startsWith('http')) {
                                    return illustration;
                                } else {
                                    return {
                                        ...illustration,
                                        link: `${baseLink}${restore_numbers_in_filename(illustration.link)}`
                                    };
                                }
                            })
                        };
                    });
                    setState((prev) => ({ ...prev, posts: modifiedData }));
                    setPagination(prevState => ({ ...prevState, total: pagination.total, last_page: pagination.last_page, current_page: pagination.current_page }));
                }
                setCompletedRequests((prev) => prev + 1);

            } catch (error) {

            }
        };

        getCreatorPost();

    }, [users.access_token]);


    const loadMorePost = useCallback(async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoadingMorePost: true }));
            const res = await RequestFanbusy(`posts/subscribe/creator?skip=${nextPage}&limit=${pagination.per_page}`, 'GET', '', users.access_token);
            if (res.status === 200) {
                const data = res.response.data.map(post => {
                    return {
                        ...post,
                        illustrations: post.illustrations.map(illustration => {

                            if (illustration.link.startsWith('http')) {
                                return illustration;
                            } else {
                                return {
                                    ...illustration,
                                    link: `${baseLink}${restore_numbers_in_filename(illustration.link)}`
                                };
                            }
                        })
                    };
                });
                const paginage = res.response.paginage;
                setPagination(prevState => ({ ...prevState, total: paginage.total, last_page: paginage.last_page, current_page: paginage.current_page }));
                setTimeout(() => {
                    setState((prevState) => ({ ...prevState, posts: [...prevState.posts, ...data], isLoadingMorePost: false }));
                }, 2000);
            }

        } catch (error) {
            setState(prevState => ({ ...prevState, isLoadingMorePost: false }));
        }
    }, [nextPage, pagination.per_page, users.access_token]);

    useEffect(() => {
        if (completedRequests === 1) {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
        const handleScroll = () => {
            if (window.innerHeight) {
                setPopoverVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [completedRequests]);

    useEffect(() => {
        let isFetching = false;
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            const scrollPosition = scrollTop + clientHeight;
            const scrollThreshold = scrollHeight - 500;

            if (!isFetching && scrollPosition >= scrollThreshold && pagination.current_page !== pagination.last_page && state.posts.length >= 5) {
                isFetching = true;
                loadMorePost();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadMorePost, pagination.current_page, pagination.last_page, state.posts.length]);


    const refrechAfterDeletePost = (postIdToDelete) => {
        setState(prevState => ({
            ...prevState,
            posts: prevState.posts.filter(post => post._id !== postIdToDelete)
        }));
    };

    const updatePostContent = (postIdToUpdate, newContent) => {
        setState(prevState => ({
            ...prevState,
            posts: prevState.posts.map(post => {
                if (post._id === postIdToUpdate) {
                    return { ...post, content: newContent };
                }
                return post;
            })
        }));
    };

    return (
        <AppContainer popoverIsVisible={isPopoverVisible} setPopoverIsVisible={setPopoverVisible}>
            <main style={{ paddingBottom: isMobile ? '30px' : '0px', }} className={` col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 px-0 border border-top-0 border-fw-light border-bottom-0  ${isMobile && 'pt-5 fixed-content mb-5  w-100'}`}>
                <div style={{ height: '50px' }} className={`mb-2 d-flex align-items-center m-none bg-white fix-sidebar top-0 shadow-sm`}>
                    <span className="fw-bold text-black text-uppercase fs-6 text-body px-3 ">🏠 {t('navigation.home')}</span>
                </div>

                {users.users?.account_type !== "CREATOR" &&
                    <BannerBecome
                        accountType={users.users?.account_type}
                        t={t}
                        viewport={isMobile}
                    />
                }

                <div className="main-content " onClick={() => handleClosePopup()}>
                    <div className={`px-2 shadow-sm ${(isMobile && users.users?.account_type === "CREATOR") ? 'pt-2' : 'pt-0'}`}>
                        {users.users?.account_type === "CREATOR" ?
                            <div className={`bg-white input-group d-flex justify-content-between align-items-center overflow-hidden  bg-white py-2`} data-bs-toggle="modal" data-bs-target="#postModal">
                                <button type="text" className={` form-control border-0 fw-light d-flex justify-content-between  ${isMobile ? 'px-3 pb-0' : 'px-2 pb-2'} mx-0`}  >
                                    <label className=''>🚀 <span className='text-gradient fw-bold'>{`${t('views.home.add_new_content')}`} . . .</span> </label>
                                </button>
                                <a href={`/`} className={`bg-white text-decoration-none input-group-text  border-0 material-icons ml-mobile ${!isMobile ? 'pb-3' : 'pb-0'}`} data-bs-toggle="modal" data-bs-target="#postModal"> <MdEditNote style={{ verticalAlign: 'unset' }} /> </a>
                            </div>
                            :
                            <div className=" position-relative">
                                <div className={`input-group d-flex justify-content-between align-items-center overflow-hidden  bg-white ${isMobile ? 'py-2' : 'pt-2'} `} onClick={() => setPopoverVisible(!isPopoverVisible)}>
                                    <button type="text" className={`form-control border-0 fw-light d-flex justify-content-between ${isMobile ? 'px-3 pb-0' : 'px-2 pb-3'} mx-0`}>
                                        <label className=''>🚀 <span className='text-gradient fw-bold'>{`${t('views.home.add_new_content')}`} . . .</span></label>
                                    </button>
                                    <div className="text-decoration-none input-group-text bg-white border-0 material-icons ml-mobile"><MdEditNote style={{ verticalAlign: 'unset' }} /></div>
                                </div>
                                {isPopoverVisible && (
                                    <div style={{ zIndex: 1200 }} className="position-absolute top-50 start-50 translate-middle-x mt-4 p-3 rounded-3 shadow-sm border bg-white">
                                        <p className="m-0 mb-2">{t('views.home.you_cannot_publish')}.</p>
                                        <a href={`${users.users?.account_type === "REQUEST_CREATOR" ? '/creator-request' : '/become-creator'}`} className="btn gradient-button text-decoration-none text-white rounded-pill fs-xSmall">{t('views.home.become_Creator')}</a>
                                    </div>
                                )}
                            </div>
                        }
                    </div>

                    <div>
                        {state.isLoading ?
                            <Loader styleColor={true} marginTop={true} /> :
                            <>
                                <div className={`${state.posts.length > 0 ? 'pt-3' : 'pt-mobile-none'}`} style={{ overflowY: 'auto' }} >
                                    {state.posts.length > 0 ? (
                                        <>
                                            {isMobile &&
                                                <div style={{ position: "static" }}>
                                                    <AsidePeople styleResp={'w-100'} />
                                                </div>
                                            }

                                            {state.posts.map((post, index) => {
                                                if ((index + 1) % 10 === 0) {
                                                    return (
                                                        <div key={index}>
                                                            <Post
                                                                post={post}
                                                                postLength={{ length: state.posts?.length, index: index }}
                                                                creatorId={users.users?._id}
                                                                bookmarks={listBookmark}
                                                                likes={listLikes}
                                                                released={listPostRelease}
                                                                follows={state.follows}
                                                                setListPostRelease={setListPostRelease}
                                                                refrechAfterDeletePost={refrechAfterDeletePost}
                                                                updatePostContent={updatePostContent}
                                                            />
                                                            {isMobile && <SuggestionContainer styleResp={'web-none'} margin={'ms-3'} />}
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div key={index} >
                                                            <Post
                                                                post={post}
                                                                postLength={{ length: state.posts?.length, index: index }}
                                                                creatorId={users.users?._id}
                                                                bookmarks={listBookmark}
                                                                likes={listLikes}
                                                                released={listPostRelease}
                                                                follows={state.follows}
                                                                setListPostRelease={setListPostRelease}
                                                                refrechAfterDeletePost={refrechAfterDeletePost}
                                                                updatePostContent={updatePostContent}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })}
                                            {(state.isLoadingMorePost) && (
                                                <div className="text-center  text-muted my-3 fw-bold fs-6">
                                                    <div className="spinner-border spinner-border-sm text-secondary" role="status">
                                                        <span className="visually-hidden small">Loading...</span>
                                                    </div>
                                                </div>

                                            )}
                                            {(pagination.current_page === pagination.last_page && state.posts.length >= 2 && !state.isLoadingMorePost) &&
                                                <EndOfPage />
                                            }

                                        </>
                                    ) : (
                                        <>
                                            {isMobile && <AsidePeople styleResp={'web-none w-100'} />}
                                            <div className='mt-5'>
                                                <NoContent message={t('views.home.no_post')} />
                                            </div>
                                        </>
                                    )}

                                </div>
                            </>
                        }
                    </div>
                </div>
            </main>
            <AsideContainer />
        </AppContainer>
    );
};


export default HomeView;