import React, { useEffect, useRef, useState } from 'react';

const VideoComponent = ({ file, post, currentIndex }) => {
    const containerRef = useRef(null);
    const videoRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                setIsVisible(entry.isIntersecting);
            },
            {
                threshold: 0.5,
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            if (isVisible) {
                videoRef.current.play().catch(() => {
                    console.log('Autoplay prevented by browser policy');
                });
            } else {
                videoRef.current.pause();
            }
        }
    }, [isVisible, currentIndex]);

    return (
        <div className="img-content" ref={containerRef}>
            <video
                ref={videoRef}
                muted
                loop
                playsInline
                controls
                controlsList="nodownload"
                preload="metadata"
                id={`post-${post._id}`}
                style={{ height: '100%', maxWidth: '100%', position: 'absolute' }}
                onError={(e) => console.error('Error loading video:', e.target.error)}
            >
                <source src={file.link} />
            </video>
        </div>
    );
};

export default VideoComponent;
