//React
import React from 'react';
import Slider from 'react-slick';
import { VideoComponent } from '../../Components';

const PostIllustrations = ({ post }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div id={`illustrationPostCarousel-${post._id}`}>
            <Slider {...settings}>
                {post.illustrations.map((file, index) => {
                    return file.mime_type === "video" ? (
                        <div key={index} className="overflow-hidden position-relative" style={{ height: 550 }}>
                            {/* <video muted controlsList="nodownload" className="blur-content" preload="metadata" style={{ height: 550, maxWidth: 760, objectFit: 'fill' }}>
                                <source src={`${file.link}#t=0.1`} type="video/mp4" />
                            </video> */}
                            <div className="bg-black" preload="metadata" style={{ height: 550, maxWidth: 760, objectFit: 'fill' }}></div>
                            <VideoComponent file={file} post={post} currentIndex={index} />
                        </div>
                    )
                        :
                        (
                            <div key={index} className="overflow-hidden position-relative" style={{ maxHeight: 550 }} data-bs-toggle="modal" data-bs-target={`#commentModal${post._id}`}>
                                <div className="blur-content" style={{ backgroundImage: `url(${file.link})`, maxHeight: 550 }}></div>
                                <div className="img-content">
                                    <div className='position-relative'>
                                        <img src={file.link} className="d-block img" alt="post-img" style={{ maxHeight: 550, maxWidth: 760, backgroundImage: `url(${file.link})`, display: 'block' }} />
                                    </div>
                                    {post.illustrations.length > 1 &&
                                        <p className="position-absolute top-0 end-0 m-2 px-2 py-1 text-white bg-black rounded-pill opacity-50 z-50">{index + 1}/{post.illustrations.length}</p>
                                    }
                                </div>
                            </div>
                        );
                })}
            </Slider>

        </div>
    );
};

export default PostIllustrations;
