import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { RequestFanbusy } from "../../Services";
import { Loader, RestoreAccountComponent } from "../../Components";
import { useScreenSize } from "../../Utils";

import { Images } from "../../Constants";
import { useTranslation } from "react-i18next";

const RestaureAccount = () => {
	const screenSize = useScreenSize()

	return (
		<div className="container position-relative width-mobile" style={{ height: '100vh' }}>
			<div className={`position-relative  ${screenSize === 'Medium' || screenSize === 'Large' || screenSize === 'Extra Large' ? 'd-flex justify-content-center' : ''} `}>
				<RestoreAccountComponent />
				<div className="d-flex justify-content-center align-items-center small-none web-none mt-3">
					<img className="mw-mobile" src={require("../../assets/img/2.png")} alt="" style={{ maxHeight: 200 }} />
				</div>
				<aside className="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12"></aside>
				<aside className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12"></aside>
			</div>
		</div>
	);
};

const RestaureAccountConfirm = () => {
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState(false)
	const [state, setState] = useState({
		verifyCode: "",
		password: "",
		confirmPassword: ""
	});
	const [error, setError] = useState({
		type: "",
		message: ""
	});
	let [searchParams, setSearchParams] = useSearchParams();

	React.useEffect(() => {
		if (searchParams.get("pwd") != null) {
			setState({ ...state, verifyCode: searchParams.get("pwd") });

			setSearchParams({})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const textInputChange = (input) => {
		const target = input.target;
		const value = target.value;
		const name = target.name;
		if (name === "password") {
			setState({ ...state, password: value });
		} else if (name === "confirmPassword") {
			setState({ ...state, confirmPassword: value });
		}
	};

	const submitNewLogin = async (event) => {
		event.preventDefault();
		if (state.password.length === 0 || state.confirmPassword.length === 0) {
			setError({ type: "error", message: t('views.auth.theFieldCannotBeEmpty') })
			return;
		}
		if (state.confirmPassword !== state.password) {
			setError({ type: "error", message: t('views.auth.passwordDontMatch') })
			return;
		}


		const form = {
			verify_code: state.verifyCode,
			password: state.password,
			passwordConfirm: state.confirmPassword,
		};
		setIsLoading(true)
		let response = await RequestFanbusy("auth/reset-password", "POST", form, "");
		if (response.status === 201) {
			setState({
				verifyCode: "",
				password: "",
				confirmPassword: "",
			});
			setIsLoading(false)
			setError({ type: "success", message: t('views.auth.yourAccountHasBeenRestored') })
		}
		else if (response.status === 400) {
			setIsLoading(false)
			console.log("400", response.response.description);
			setError({ type: "error", message: t('views.auth.passwordDontMatch') })
		}
		else {
			setIsLoading(false)
			setError({ type: "error", message: t('views.auth.anError') })
		}
	};

	return (
		<>
			<div className="py-4">
				<div className="container">
					<div className="row position-relative">
						<main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 mt-5 mb-5">
							<div className="main-content">
								<div className="mt-5">
									<header className="profile d-flex align-items-center ml-25 ">
										<img style={{ width: "150px", height: "30px", objectFit: "contain" }} src={Images.logov2} alt="Logo" className="web-none" />
										<img style={{ width: "250px", height: "70px", objectFit: "contain" }} src={Images.logov2} alt="Logo" className="m-none" />
									</header>
									<div className="mb-3">
										<p className="mb-0 text-dark m-none d-flex align-items-center ">
											<div>
												{/* <label className="fs-5 text-center">{t('views.auth.aWorlOfInfinite')}</label> */}
												<span className="fs-5">{t('views.auth.stitle1')} <span className=" text-gradient fw-bold ">{t('views.auth.stitle2')}</span></span>

											</div>
										</p>
										<p className="mb-0 text-dark web-none">
											{/* <span className="fs-6 text-center">{t('views.auth.aWorlOfInfinite')}</span> */}
											<span className="fs-6">{t('views.auth.stitle1')} <span className=" text-gradient fw-bold ">{t('views.auth.stitle2')}</span></span>

										</p>
									</div>
									<div>
										<p className="mb-0 text-dark m-none d-flex align-items-center ms-0">
											<div>
												<label className="fs-5 fw-bold">{t('views.auth.enterYourNewLoginInformation')}</label>
											</div>
										</p>
										<p className="mb-0 text-dark web-none">
											<span className="fs-6 fw-bold">{t('views.auth.enterYourNewLoginInformation')}</span>
										</p>
									</div>
								</div>

								<div className="feeds">
									<div className={`bg-white pt-0 px-0 feed-item mb-3 faq-page mb-sm-none pb-sm-none`}>
										<div className="row justify-content-center">
											<div className="col-lg-12">
												<form>
													<div className="form-floating mb-3 d-flex align-items-center">
														<input type="password" name="password" value={state.password} onChange={textInputChange} className="form-control rounded-5" id="password" placeholder="Password" />
														<label htmlFor="password text-uppercase ">{t('views.auth.newPassword')}</label>
													</div>
													<div className="form-floating mb-3 d-flex align-items-center">
														<input type="password" name="confirmPassword" value={state.confirmPassword} onChange={textInputChange} className="form-control rounded-5" id="password" placeholder="Confirm Password" />
														<label htmlFor="password text-uppercase">{t('views.auth.confirm_password')}</label>
													</div>
													<div className="d-grid">
														<button onClick={submitNewLogin} className="btn gradient-button text-white rounded-pill mt-2 w-100 text-decoration-none py-2 fw-bold text-uppercase m-0 gradient-button">
															{isLoading ? <Loader /> : t('words.send')}
														</button>
													</div>
												</form>
											</div>
											<div className="mt-3 text-center">
												{error.type === "success" &&
													<div className="alert alert-success alert-dismissible mt-2" role="alert">
														<div className="fw-bold">{error.message}<a href={`/auth/login`}>{t('views.auth.clickHere')}</a> {t('views.auth.toLogInAgain')} </div>
														<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
													</div>
												}
												{error.type === "error" &&
													<div className="alert alert-danger alert-dismissible mt-2" role="alert">
														<div className="fw-bold">{error.message}</div>
														<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
						<aside className="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12"></aside>
						<aside className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12"></aside>
					</div>
				</div>
			</div>
		</>
	);
};

export { RestaureAccount, RestaureAccountConfirm };
