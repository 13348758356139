const Loader = ({ styleColor, marginTop, fontsize }) => {
    return (
        <div className={`text-center ${marginTop ? 'mt-4' : ''} ${styleColor ? 'text-secondary' : 'text-white'}`}>
            <div className={`spinner-border spinner-border-sm ${!styleColor ? 'spinner-border-sm' : ''}`} role="status">
                <span className={`visually-hidden ${fontsize}`} >Loading ...</span>
            </div>
        </div>
    )
}

export default Loader;