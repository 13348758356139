import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { getBookmark, getCreatorFollows, getLikes, getReleasePost, getStatutByUser, useScreenSize } from '../../Utils';
import { AppContainer, AsideContainer } from '../../Containers';
import { RequestFanbusy } from '../../Services';
import { Avatar, DescriptionParagraph, EndOfPage, Loader, NoContent, Post, ShareModal } from '../../Components';
import { Images } from '../../Constants';
import { getMyFAns } from '../../Utils';
import { shortenName, restore_numbers_in_filename } from '../../Utils';
// import CollectionsContainer from '../../feature/collections/collectionsContainer';


const ProfileView = () => {
    const { t } = useTranslation()
    const darkMode = useSelector((state) => state.theme.darkMode);
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const baseLink = 'https://dyu0tgtvswtj8.cloudfront.net/fanbusy_'
    const [state, setState] = React.useState({
        posts: [],
        follows: [],
        myFans: [],
        collections: [],
        isLoading: true,
        isLoadingMorePost: false,
        isLoadingCollection: true,

    });

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 5,
        last_page: 0,
        current_page: 0,
    })
    const nextPage = pagination.current_page + 1;
    // const [activeTab, setActiveTab] = React.useState("Posts");
    const [listBookmark, setListBookmark] = React.useState([]);
    const [listLikes, setListLikes] = React.useState([]);
    const [listPostRelease, setListPostRelease] = React.useState([]);
    const [scrollY, setScrollY] = useState(0);
    const [isOnLine, setIsOnLine] = React.useState(false);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    React.useEffect(() => {
        let bookmarks = getBookmark(users.access_token);
        bookmarks.then((value) => {
            setListBookmark(value);
        });

        let likes = getLikes(users.access_token, 'POST');
        likes.then((value) => {
            setListLikes(value);
        });

        let releasePost = getReleasePost(users.access_token);
        releasePost.then((value) => {
            setListPostRelease(value);
        });

        let follows = getCreatorFollows(users.access_token);
        follows.then((value) => {
            setState((prev) => ({ ...prev, follows: value, isLoading: false }));
        });

        const getPostSelfBycreator = async () => {
            let res = await RequestFanbusy("posts/self/creator", 'GET', '', users.access_token);
            const pagination = res.response.paginage;
            if (res.status === 200) {
                const modifiedData = res.response.data.map(post => {
                    return {
                        ...post,
                        illustrations: post.illustrations.map(illustration => {

                            if (illustration.link.startsWith('http')) {
                                return illustration;
                            } else {
                                return {
                                    ...illustration,
                                    link: `${baseLink}${restore_numbers_in_filename(illustration.link)}`
                                };
                            }
                        })
                    };
                });
                setState((prev) => ({ ...prev, posts: modifiedData, isLoading: false }));
                setPagination(prevState => ({ ...prevState, total: pagination.total, last_page: pagination.last_page, current_page: pagination.current_page }));
            }
        };

        const baseLink = "https://dyu0tgtvswtj8.cloudfront.net/";

        getPostSelfBycreator();


        // const getCollectionSelfBycreator = async () => {
        //     let res = await RequestFanbusy("collection/self/creator", 'GET', '', users.access_token);
        //     // const pagination = res.response.paginage;
        //     if (res.status === 200) {
        //         setState((prev) => ({ ...prev, collections: res.response.data, isLoadingCollection: false }));
        //         // setPagination(prevState => ({ ...prevState, total: pagination.total, last_page: pagination.last_page, current_page: pagination.current_page }));

        //     }
        // }
        // getCollectionSelfBycreator();

    }, [params.refresh, users.access_token]);

    const loadMorePost = useCallback(async () => {
        try {
            setState(prevState => ({ ...prevState, isLoadingMorePost: true }));
            const res = await RequestFanbusy(`posts/self/creator?skip=${nextPage}&limit=${pagination.per_page}`, 'GET', '', users.access_token);
            if (res.status === 200) {
                const data = res.response.data.map(post => {
                    return {
                        ...post,
                        illustrations: post.illustrations.map(illustration => {
                            if (illustration.link.startsWith('http')) {
                                return illustration;
                            } else {
                                return {
                                    ...illustration,
                                    link: `${baseLink}${restore_numbers_in_filename(illustration.link)}`
                                };
                            }
                        })
                    };
                });
                const paginage = res.response.paginage;
                setPagination(prevState => ({ ...prevState, total: paginage.total, last_page: paginage.last_page, current_page: paginage.current_page }));
                setTimeout(() => {
                    setState((prevState) => ({ ...prevState, posts: [...prevState.posts, ...data], isLoadingMorePost: false }));
                }, 2000);
            }
        } catch (error) {
            setState(prevState => ({ ...prevState, isLoadingMorePost: false }));
        }
    }, [nextPage, pagination.per_page, users.access_token]);


    useEffect(() => {
        let online = getStatutByUser(users.users._id);
        online.then((value) => {
            setIsOnLine(value)
        });
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [users.users._id]);

    React.useEffect(() => {
        const res = getMyFAns(users.access_token)
        res.then((res) => {
            if (res.status === 200) {
                setState((prev) => ({ ...prev, getMyFAns: res.response.data }));
            }
        })

    }, [params.refresh, users.access_token]);

    useEffect(() => {
        let isFetching = false;

        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            const scrollPosition = scrollTop + clientHeight;
            const scrollThreshold = scrollHeight - 500;

            if (!isFetching && scrollPosition >= scrollThreshold && pagination.current_page !== pagination.last_page && state.posts.length >= 5) {
                isFetching = true;
                loadMorePost();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadMorePost, pagination.current_page, pagination.last_page, state.posts.length]);

    const refrechAfterDeletePost = (postIdToDelete) => {
        setState(prevState => ({
            ...prevState,
            posts: prevState.posts.filter(post => post._id !== postIdToDelete)
        }));
    };

    const updatePostContent = (postIdToUpdate, newContent) => {
        setState(prevState => ({
            ...prevState,
            posts: prevState.posts.map(post => {
                if (post._id === postIdToUpdate) {
                    return { ...post, content: newContent };
                }
                return post;
            })
        }));
    };

    // const changeTab = (tab) => {
    //     setActiveTab(tab);
    // };

    // function deleteCollection(collectionId) {
    //     setState((prev) => ({
    //         ...prev, collections: state.collections.filter(collection => collection.id !== collectionId)
    //     }))
    //     RequestFanbusy(`collection/${collectionId}`, 'DELETE', "", users.access_token);

    // }

    return (
        <AppContainer>
            <main style={{ paddingBottom: isMobile ? '30px' : '50px', }} className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 border border-top-0 border-fw-light px-0  border-bottom-0 ${isMobile && 'pb-5 '} `}>
                <div className="main-content c-pb ">
                    {scrollY >= 260 && (
                        <div style={{ height: '50px' }} className={`d-flex justify-content-between align-items-center mb-3 bg-white fix-sidebar top-0 border-bottom px-4`}>
                            <div className='d-flex'>
                                <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none me-1 `} >
                                    arrow_back
                                </a>
                                <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-capitalize d-flex align-items-center`}>
                                    <span className="m-0 p-0">{shortenName(users.users.display_name, 15)}</span>
                                    {users.users.account_type === 'CREATOR' &&
                                        <span className="ms-1 p-0 md-16 fw-bold ">{Images.verif} </span>
                                    }
                                </p>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <a href={`/profile-edit`} title="Edit Profile" className="text-dark ">
                                        <span className="material-icons my-1 fs-6 me-2">border_color</span>
                                    </a>
                                    <div className="dropdown">
                                        <a href={`/`} style={{ paddingTop: "2px" }} className=" text-decoration-none material-icons ms-2 md-20 rounded-circle bg-transparent  text-dark " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_vert</a>
                                        <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target={`#shareModal${users.users.pseudo}`}>
                                                    <span className="material-icons md-13 me-1">share</span>{t('views.profile.shareViaAnotherApps')}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}

                    <div className={`${darkMode ? 'bg-dark' : 'bg-white'} shadow-sm profile position-relative pb-2 p-0`} style={{ position: 'relative' }}>
                        <div style={{ backgroundImage: `url(${users.users.banner.length === 0 ? Images.background : users.users.banner})`, backgroundSize: 'cover' }}>
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, .10)', height: '200px', }}>
                                <div className={`d-flex flex-column gap-2 pt-2 ps-3`}>
                                    <div className={` d-flex align-items-center `}>
                                        <a href={`/`} className={`text-white material-icons text-decoration-none ms-0`}>arrow_back</a>
                                        <p className={`text-white ms-2 mb-0 fw-bold mt-0 fs-6 text-capitalize d-flex align-items-center`}>
                                            <span className="m-0 p-0">{shortenName(users.users.display_name, 15)}</span>
                                            {users.users.account_type === 'CREATOR' &&
                                                <span className="mb-0 ms-1 pb-1 md-16 fw-bold">{Images.verifWhite} </span>
                                            }
                                        </p>
                                    </div>
                                    <div className="d-flex  gap-3  pb-0 followers text-white fw-bold small ms-web ms-2 ">
                                        {state.follows?.length > 0 && <p className="mb-0 fw-bold">{state.follows?.length} {state.follows.length > 1 ? t('words.follows') : t('words.follow')}</p>}
                                        {(users.users.account_type === "CREATOR" && state.getMyFAns?.length > 0) && <p className="mb-0 fw-bold">{state.getMyFAns?.length} {state.getMyFAns.length > 1 ? t('words.fans') : t('words.fan')}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center  px-3 pt-1 pb-2">
                            <div style={{ marginTop: -50, position: 'relative' }}>
                                <Avatar user={users.users} width={80} height={80} fontSize="fs-4" />
                                {isOnLine &&
                                    <span className="rounded-circle position-absolute bottom-0 mb-2" style={{ width: 14, height: 14, right: '4px', background: '#35D063', border: '2px solid white' }}></span>
                                }
                            </div>

                            <div className=" ms-auto d-flex align-items-end">
                                <a href={`/profile-edit`} title={`${t('views.editProfile.editProfile')}`} className=" border-0 px-3 rounded-pill btn gradient-button text-white fw-bold  ">
                                    <span className="material-icons  small ">border_color </span><label className='text-uppercase ms-2 small' >{t('views.editProfile.editProfile')} </label>
                                </a>
                            </div>
                        </div>

                        <div className="pb-2 pt-1 px-3">
                            <div className="mb-2 ">
                                <h6 className={`${darkMode ? 'tex-white' : 'text-body'} mb-0 d-flex align-items-center fs-6 fw-bold text-capitalize`}>
                                    <span className="m-0 p-0">{shortenName(users.users.display_name, 28)}</span>
                                    {users.users.account_type === 'CREATOR' &&
                                        <span className="ms-1 p-0 md-16 fw-bold ">{Images.verif} </span>
                                    }
                                </h6>
                                <p className="text-muted mb-0">@{shortenName(users.users.pseudo, 28)}</p>
                            </div>

                            <DescriptionParagraph bio={users.users.bio} profile={true} />

                            {/* <div >
                                <ul className="nav nav-pills justify-content-center nav-justified overflow-hidden" id="pills-tab" role="tablist">
                                    <li className="nav-item m-2 mb-0 ms-0" role="presentation" onClick={() => { }}>
                                        <button className={`nav-link pe-10 text-dark ${activeTab === "Posts" ? "active bg-transparent fw-bold" : ""}`} id="pills-feed-tab" data-bs-toggle="pill" data-bs-target="#pills-feed" type="button" role="tab" aria-controls="pills-feed" aria-selected={activeTab === "YOUR CARDS"} onClick={() => changeTab("Posts")}>
                                            <span className="text-gradient fw-bold fs-6"> Posts</span>
                                        </button>
                                        {activeTab === "Posts" &&
                                            <span className="gradient-button d-block mb-0" style={{ width: "100%", height: 3 }}></span>
                                        }
                                    </li>
                                    <li className="nav-item m-2 mb-0 me-0" role="presentation" onClick={users.users.account_type === 'CREATOR' ? () => { } : () => { }}>
                                        <button className={`nav-link  ${activeTab === "Collections" ? "active bg-transparent fw-bold" : ""}`} id="pills-people-tab" data-bs-toggle="pill" data-bs-target="#pills-people" type="button" role="tab" aria-controls="pills-people" aria-selected={activeTab === "Collections"} onClick={() => changeTab("Collections")} >
                                            <span className="text-gradient fw-bold fs-6">Collections</span>
                                        </button>
                                        {activeTab === "Collections" &&
                                            <span className="gradient-button d-block" style={{ width: "100%", height: 3 }}></span>
                                        }
                                    </li>
                                </ul>
                            </div> */}

                        </div>

                        <div style={{ position: 'absolute', top: 5, right: 5 }}>
                            <div className="dropdown">
                                <a href={`/`} className=" text-decoration-none material-icons ms-2 md-20 rounded-circle bg-transparent  text-white p-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_vert</a>
                                <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target={`#shareModal${users.users.pseudo}`}>
                                            <span className="material-icons md-13 me-1">share</span>{t('views.profile.shareViaAnotherApps')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="pt-4 feeds">
                        {state.isLoading ?
                            <Loader styleColor={true} /> :
                            < >
                                {state.posts.length > 0 ?
                                    state.posts.map((post, index) => {
                                        return (
                                            <Post
                                                key={post._id}
                                                postLength={{ length: state.posts?.length, index: index }}
                                                post={post} creatorId={users.users._id}
                                                bookmarks={listBookmark}
                                                likes={listLikes}
                                                released={listPostRelease}
                                                follows={state.follows}
                                                setListPostRelease={setListPostRelease}
                                                refrechAfterDeletePost={refrechAfterDeletePost}
                                                updatePostContent={updatePostContent}
                                            />
                                        )
                                    })
                                    : users.users.account_type === 'CREATOR' ?
                                        <NoContent message={'You have no publications at the moment'} />
                                        :
                                        <NoContent message={'You have no publication because you are not a creator'} />
                                }
                                {(state.isLoadingMorePost) && (
                                    <div className="text-center  text-muted my-3 fw-bold fs-6">
                                        <div className="spinner-border spinner-border-sm text-secondary" role="status">
                                            <span className="visually-hidden small">Loading...</span>
                                        </div>
                                    </div>

                                )}
                                {(pagination.current_page === pagination.last_page && !state.isLoadingMorePost && state.posts.length >= 2) &&
                                    (<EndOfPage />)
                                }
                            </>
                        }
                    </div>
                    {/* {activeTab === 'Posts' ?
                        <></>
                        :
                         <>

                             <section>
                             <CollectionsContainer
                                    addClassName={'mt-2'}
                                 collections={state.collections}
                                 follows={state.follows}
                                 released={listPostRelease}
                                 deleteCollection={deleteCollection}
                                 isLoading={state.isLoadingCollection}
                             />
                         </section>
                     </>} */}

                </div>
            </main>

            <ShareModal id={users.users.pseudo} postId={false} share={t('navigation.shareProfile')} />

            <AsideContainer />
        </AppContainer >
    )
}

export default ProfileView;