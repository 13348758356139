//React component
import React from "react"
import Slider from 'react-slick';


const PostIllustrationsModal = ({ post }) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    return (
        <div className="modal fade" id={`commentModal${post._id}`} tabIndex="-1" aria-labelledby="exampleModalLabel2" data-bs-ride="false" aria-hidden="true" data-bs-interval="false" onContextMenu={(e) => e.preventDefault()}>
            <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                <div className={`modal-content rounded-4 overflow-hidden border-0`}>
                    <div className="modal-body p-0" style={{ height: '100%' }}>
                        <div className="" style={{ height: '100%' }}>
                            <div id={`illustrationPostModalCaousel-${post._id}`} className="carousel slide" data-bs-ride="false" data-bs-interval="false" data-bs-touch="true" style={{ height: '100%' }}>
                                <Slider {...settings}>
                                    {post.illustrations.map((file, index) => {
                                        return file.mime_type !== "video" && (
                                            <div key={index} className={`overflow-hidden position-relative`} style={{ height: '100%', width: '100%' }} data-bs-toggle="modal" data-bs-target={`#commentModal${post._id}`}>
                                                <div className="blur-content" style={{ backgroundImage: `url(${file.link})` }}></div>
                                                <div className="img-content">
                                                    <div className='position-relative ms-3'>
                                                        <img src={file.link} className="d-block img" alt="post-img" style={{ maxHeight: 705, maxWidth: '95%', backgroundImage: `url(${file.link})` }} />
                                                    </div>
                                                    {post.illustrations.length > 1 &&
                                                        <p className="position-absolute top-0 end-0 m-2 px-2 py-1 text-white bg-black rounded-pill opacity-50">{index + 1}/{post.illustrations.length}</p>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PostIllustrationsModal